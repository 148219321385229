"use strict";

require("core-js/modules/es.object.define-property.js");
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateMetricHeight = exports.validateImperialHeight = exports["default"] = void 0;
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.number.constructor.js");
require("core-js/modules/es.regexp.constructor.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.regexp.to-string.js");
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.string.includes.js");
var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _i18n = require("../../i18n");
var _excluded = ["name"],
  _excluded2 = ["name"];
// Validate that height is with in the same range that is used for imperial
// height measurement.
var validateMetricHeight = exports.validateMetricHeight = function validateMetricHeight(_ref) {
  var name = _ref.name,
    rest = (0, _objectWithoutProperties2["default"])(_ref, _excluded);
  return function (value) {
    if (value && value["".concat(name, "_units")] === 'centimeters' && rest !== null && rest !== void 0 && rest.metric_min && rest !== null && rest !== void 0 && rest.metric_max) {
      var metric_min = rest.metric_min,
        metric_max = rest.metric_max;
      if ((value === null || value === void 0 ? void 0 : value[name]) >= Number(metric_min) && (value === null || value === void 0 ? void 0 : value[name]) <= Number(metric_max)) {
        return undefined;
      }
      return (0, _i18n.globalTranslate)('validations.heightInternational.customMetricMinMax', {
        min: Number(metric_min),
        max: Number(metric_max)
      });
    }
    return undefined;
  };
};

// Test to see if inches exists.
var validateImperialHeight = exports.validateImperialHeight = function validateImperialHeight(_ref2) {
  var name = _ref2.name,
    rest = (0, _objectWithoutProperties2["default"])(_ref2, _excluded2);
  return function (value) {
    if ((value === null || value === void 0 ? void 0 : value["".concat(name, "_units")]) === 'inches' && value !== undefined && (0, _typeof2["default"])(value) === 'object' && value[name]) {
      var isNumberRegex = /^\d+$|^\d+\.\d+$/;
      var inches = Number(value[name].inch);
      var feet = Number(value[name].ft);
      // Case: user inputs Not number values
      if (!isNumberRegex.test(value[name].inch) || !isNumberRegex.test(value[name].ft)) {
        return (0, _i18n.globalTranslate)('validations.heightInternational.customImperialMinMax', {
          min: rest === null || rest === void 0 ? void 0 : rest.imperial_feet_min,
          max: rest === null || rest === void 0 ? void 0 : rest.imperial_feet_max,
          minInches: (rest === null || rest === void 0 ? void 0 : rest.imperial_inches_min) || 0,
          maxInches: (rest === null || rest === void 0 ? void 0 : rest.imperial_inches_max) || 11
        });
      }
      // Case: user does not input any values for inches || feet
      if ((value[name].inch === null || value[name].inch === undefined || String(value[name].inch).length === 0) && (value[name].ft === null || value[name].ft === undefined || String(value[name].ft).length === 0)) {
        return (0, _i18n.globalTranslate)('validations.heightInternational.imperialErrorMsg');
      }
      // Case: the user leaves the inches field empty
      if (value["".concat(name, "_units")] === 'inches' && (value[name].inch === null || value[name].inch === undefined || String(value[name].inch).length === 0)) {
        return (0, _i18n.globalTranslate)('validations.heightInternational.customImperialMinMax', {
          min: rest === null || rest === void 0 ? void 0 : rest.imperial_feet_min,
          max: rest === null || rest === void 0 ? void 0 : rest.imperial_feet_max,
          minInches: rest === null || rest === void 0 ? void 0 : rest.imperial_inches_min,
          maxInches: rest === null || rest === void 0 ? void 0 : rest.imperial_inches_max
        });
      }
      // Case: inches decimal places out of bounds
      if (rest.imperial_inches_decimal_places !== undefined && String(rest.imperial_inches_decimal_places).length && Number(rest.imperial_inches_decimal_places) > 0) {
        var decimalPlacesRegex = new RegExp("^\\d+$|^\\d+\\.\\d{0,".concat(Number(rest.imperial_inches_decimal_places), "}$"));
        if (!decimalPlacesRegex.test(String(inches))) {
          return (0, _i18n.globalTranslate)('validations.heightInternational.customImperialMaxDecimalPlaces', {
            decimals: rest === null || rest === void 0 ? void 0 : rest.imperial_inches_decimal_places
          });
        }
      }
      // case: feet value with decimals
      if (String(feet).includes('.')) {
        return (0, _i18n.globalTranslate)('validations.heightInternational.customImperialFeetDecimalPlaces');
      }
      // Case: inches values is negative or grater or equal to 12(1 foot)
      if (inches < 0 || inches >= 12) {
        return (0, _i18n.globalTranslate)('validations.heightInternational.customImperialMinMaxInchesBounds', {
          min: (rest === null || rest === void 0 ? void 0 : rest.imperial_inches_min) || 0,
          max: (rest === null || rest === void 0 ? void 0 : rest.imperial_inches_max) || 11
        });
      }
      if (rest !== null && rest !== void 0 && rest.imperial_feet_min && rest.imperial_feet_max && rest.imperial_inches_min && rest.imperial_inches_max) {
        // case: feet and inches out of bounds
        if ((feet < Number(rest === null || rest === void 0 ? void 0 : rest.imperial_feet_min) || feet > Number(rest === null || rest === void 0 ? void 0 : rest.imperial_feet_max)) && (inches < Number(rest === null || rest === void 0 ? void 0 : rest.imperial_inches_min) || inches > Number(rest === null || rest === void 0 ? void 0 : rest.imperial_inches_max))) {
          return (0, _i18n.globalTranslate)('validations.heightInternational.customImperialMinMax', {
            min: rest === null || rest === void 0 ? void 0 : rest.imperial_feet_min,
            max: rest === null || rest === void 0 ? void 0 : rest.imperial_feet_max,
            minInches: (rest === null || rest === void 0 ? void 0 : rest.imperial_inches_min) || 0,
            maxInches: (rest === null || rest === void 0 ? void 0 : rest.imperial_inches_max) || 11
          });
        }
        if (feet < Number(rest === null || rest === void 0 ? void 0 : rest.imperial_feet_min) || feet > Number(rest === null || rest === void 0 ? void 0 : rest.imperial_feet_max)) {
          // Case: feet value out of bounds
          return (0, _i18n.globalTranslate)('validations.heightInternational.customImperialMinMaxFeetBounds', {
            min: rest === null || rest === void 0 ? void 0 : rest.imperial_feet_min,
            max: rest === null || rest === void 0 ? void 0 : rest.imperial_feet_max
          });
        }
        // Case: inches value out of bounds
        if (inches < Number(rest === null || rest === void 0 ? void 0 : rest.imperial_inches_min) || inches > Number(rest === null || rest === void 0 ? void 0 : rest.imperial_inches_max)) {
          return (0, _i18n.globalTranslate)('validations.heightInternational.customImperialMinMaxInchesBounds', {
            min: rest === null || rest === void 0 ? void 0 : rest.imperial_inches_min,
            max: rest === null || rest === void 0 ? void 0 : rest.imperial_inches_max
          });
        }
      }
    }
    return undefined;
  };
};
var HeightValidations = function HeightValidations(props) {
  return [validateMetricHeight(props), validateImperialHeight(props)];
};
var _default = exports["default"] = HeightValidations;