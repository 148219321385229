var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var Copy = function (props) {
    return (React.createElement("svg", __assign({ width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M16.25 1.25H5.78125C5.69531 1.25 5.625 1.32031 5.625 1.40625V2.5C5.625 2.58594 5.69531 2.65625 5.78125 2.65625H15.4688V16.0938C15.4688 16.1797 15.5391 16.25 15.625 16.25H16.7188C16.8047 16.25 16.875 16.1797 16.875 16.0938V1.875C16.875 1.5293 16.5957 1.25 16.25 1.25ZM13.75 3.75H3.75C3.4043 3.75 3.125 4.0293 3.125 4.375V14.7402C3.125 14.9062 3.19141 15.0645 3.30859 15.1816L6.69336 18.5664C6.73633 18.6094 6.78516 18.6445 6.83789 18.6738V18.7109H6.91992C6.98828 18.7363 7.06055 18.75 7.13477 18.75H13.75C14.0957 18.75 14.375 18.4707 14.375 18.125V4.375C14.375 4.0293 14.0957 3.75 13.75 3.75ZM6.83594 16.7227L5.1543 15.0391H6.83594V16.7227ZM12.9688 17.3438H8.08594V14.5703C8.08594 14.1387 7.73633 13.7891 7.30469 13.7891H4.53125V5.15625H12.9688V17.3438Z", fill: "#1A1A1A" })));
};
