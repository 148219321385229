import { useEffect, useState } from 'react';
import { getCurrentUser, AuthUser } from 'aws-amplify/auth';

export function useUserSession() {
  const [session, setSession] = useState<AuthUser | null>(null);
  useEffect(() => {
    let isCancelled = false;
    const fetchUser = async () => {
      if (!isCancelled) {
        try {
          const currentUser = await getCurrentUser();
          setSession(currentUser);
        } catch {}
      }
    };
    fetchUser();
    return () => {
      isCancelled = true;
    };
  }, []);
  return session;
}
