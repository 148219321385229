var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled, { css } from 'styled-components';
var FlexWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: ", ";\n  align-items: ", ";\n  width: ", ";\n  justify-content: ", ";\n  row-gap: ", ";\n  column-gap: ", ";\n  gap: ", ";\n  ", "\n  ", "\n"], ["\n  display: flex;\n  flex-direction: ", ";\n  align-items: ", ";\n  width: ", ";\n  justify-content: ", ";\n  row-gap: ", ";\n  column-gap: ", ";\n  gap: ", ";\n  ", "\n  ", "\n"])), function (props) { return props.direction; }, function (props) { return props.items; }, function (props) { return (props.fullWidth ? '100%' : undefined); }, function (props) { return props.justify; }, function (props) { return props.rowGap; }, function (props) { return props.colGap; }, function (props) { return props.gap; }, function (props) {
    return props.spaceX > 0 && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      > * {\n        margin-right: ", "rem;\n      }\n    "], ["\n      > * {\n        margin-right: ", "rem;\n      }\n    "])), (props.spaceX * 4) / 16);
}, function (props) {
    return props.spaceY > 0 && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      > * {\n        margin-top: ", "rem;\n      }\n    "], ["\n      > * {\n        margin-top: ", "rem;\n      }\n    "])), (props.spaceY * 4) / 16);
});
var Flex = function (_a) {
    var children = _a.children, _b = _a.justify, justify = _b === void 0 ? 'flex-start' : _b, _c = _a.items, items = _c === void 0 ? 'flex-start' : _c, _d = _a.direction, direction = _d === void 0 ? 'row' : _d, _e = _a.spaceX, spaceX = _e === void 0 ? 0 : _e, _f = _a.spaceY, spaceY = _f === void 0 ? 0 : _f, _g = _a.fullWidth, fullWidth = _g === void 0 ? false : _g, _h = _a.rowGap, rowGap = _h === void 0 ? '' : _h, _j = _a.colGap, colGap = _j === void 0 ? '' : _j, _k = _a.gap, gap = _k === void 0 ? '' : _k, rest = __rest(_a, ["children", "justify", "items", "direction", "spaceX", "spaceY", "fullWidth", "rowGap", "colGap", "gap"]);
    return (React.createElement(FlexWrapper, __assign({ "data-testid": "t-flex", fullWidth: fullWidth, justify: justify, items: items, spaceX: spaceX, spaceY: spaceY, direction: direction, rowGap: rowGap, colGap: colGap, gap: gap }, rest), children));
};
export default Flex;
var templateObject_1, templateObject_2, templateObject_3;
