import {
  BASE_HREF,
  COOKIE_ACM_ID,
  COOKIE_PARTICIPANT_AUTH,
  COOKIE_PORTAL_EMAIL,
  STUDY_ID,
  TRIAGE_API_VERSION,
  TRIAGE_ENDPOINT,
} from '../../constants';
import api, {
  getPreviewToken,
  getSessionExpiredRedirectUrl,
} from '../../services';
import _ from 'lodash';
import { RSAA } from 'redux-api-middleware';
import { getUrlParams } from '../../utils';
import { logLegacyReduxApiError } from '../../utils/errorLogger';
import {
  REDUX_API_FAILURE,
  log_failure_with_request_data,
} from '../errors/actions';
import { userAgentMetaHeaders } from '../../utils/fingerprint';
import { parseParticipantAuthTokenExpiresHeader } from '../../utils/authExpiration';
import { AnyAction } from 'redux';
import { PARTICIPANT_STATE } from '../../types/states';
import { META, Node, SignIn } from '../../types/api';
import {
  ErrorActionType,
  InteractionActionType,
  ParticipantActionType,
  SurveyActionType,
} from '../../types/actions';
import { RootState, store } from '..';

const baseURL = `${TRIAGE_ENDPOINT}/api/${TRIAGE_API_VERSION}/studies/${STUDY_ID}/`;

export const v3URL = `${TRIAGE_ENDPOINT}/api/v3/`;
type PARTICIPANT_DATA = PARTICIPANT_STATE & SignIn;
const SUCCESS: AnyAction = {
  type: ParticipantActionType.USER_LOGIN,
  payload: (
    action: AnyAction,
    state: PARTICIPANT_DATA,
    res: any,
  ): PARTICIPANT_DATA =>
    res.json().then((data: SignIn) => {
      const expiresHeader = res.headers.get('participant-auth-token-expires');
      if (!expiresHeader) {
        return data;
      }
      return {
        ...data,
        auth_token_expiration:
          parseParticipantAuthTokenExpiresHeader(expiresHeader),
      };
    }),
};
const FAILURE = REDUX_API_FAILURE;

const authHeader = (auth_token: string = '') => ({
  'Content-Type': 'application/json',
  'Participant-Auth-Token': auth_token,
  'Preview-Token': getPreviewToken(),
});

export const defaultMessage = `An error has occurred. Please try again in a few minutes, or click "Contact Us" for assistance.`;
export const loginFailed =
  'Login failed; invalid email or password. Please try again. If you forgot your password, please click the “Forgot Your Password” button.';

export const loginCreatePwRequired = 'Hello World';

export const parseLoginError = (request: { status: number }) => {
  switch (request.status) {
    case 401:
      return loginFailed;
    case 404:
    case 500:
      return defaultMessage;
    default:
      return defaultMessage;
  }
};

const loginError = (payload: any) => ({
  type: ErrorActionType.ERROR_ADD,
  payload: async (action: AnyAction, state: RootState, response: Response) => {
    logLegacyReduxApiError({
      action,
      state,
      response,
      request: payload,
    });
    try {
      // PRSM-2091 Parse out original server
      // this will be used by the login component
      // to display custom messaging based on situation
      const originalError = await response.json();
      return {
        json: {
          message: parseLoginError(response),
          originalError,
        },
        status: response.status,
      };
    } catch (_err: unknown) {
      // PRSM-2091 If unable to parse error json fallback to normal behavior
      return {
        json: {
          message: parseLoginError(response),
        },
        status: response.status,
      };
    }
  },
});

export const loginUser = (
  { meta, ...userData }: { meta?: META; [key: string]: any },
  enrollment_identifier?: string,
  auth_token?: string,
) => ({
  [RSAA]: {
    endpoint: baseURL + `enrollments/sign_in`,
    method: `POST`,
    body: JSON.stringify(userData),
    headers: {
      ...authHeader(auth_token),
      ...userAgentMetaHeaders(meta),
    },
    types: [
      ParticipantActionType.USER_REQUEST,
      SUCCESS,
      loginError(userData as PARTICIPANT_DATA),
    ],
  },
});

export const createPassword = ({
  meta,
  ...userData
}: {
  meta?: META;
  [key: string]: any;
}) => ({
  [RSAA]: {
    endpoint: v3URL + `achievement_password`,
    method: `POST`,
    body: JSON.stringify(userData),
    headers: {
      'Content-Type': 'application/json',
      ...userAgentMetaHeaders(meta),
    },
    types: [
      ParticipantActionType.USER_CREATE_PASSWORD,
      SUCCESS,
      loginError(userData as PARTICIPANT_DATA),
    ],
  },
});

export const authenticateUser = (
  payload: any,
  enrollment_identifier: string,
  auth_token: string,
) => ({
  [RSAA]: {
    endpoint: v3URL + `enrollments/${enrollment_identifier}/reauthenticate`,
    method: `POST`,
    body: JSON.stringify(payload),
    headers: authHeader(auth_token),
    types: [ParticipantActionType.USER_REQUEST, SUCCESS, loginError(payload)],
  },
});

export const updateUser = (
  {
    recaptcha_token,
    form_action,
    ...rest
  }: {
    recaptcha_token?: string;
    form_action?: string;
    [key: string]: any;
  },
  enrollment_identifier: string,
  auth_token: string,
) => ({
  [RSAA]: {
    endpoint: baseURL + `enrollments/${enrollment_identifier}`,
    method: `PUT`,
    body: JSON.stringify({
      user_data: _.isUndefined(rest) ? {} : { ...rest },
      recaptcha_token,
      form_action,
    }),
    headers: authHeader(auth_token),
    types: [
      ParticipantActionType.USER_REQUEST,
      SUCCESS,
      log_failure_with_request_data(rest),
    ],
  },
});

export const createUser = ({
  recaptcha_token,
  form_action,
  meta,
  ...rest
}: {
  recaptcha_token?: string;
  form_action?: string;
  meta?: META;
  [key: string]: any;
}) => {
  const locale = rest.locale; // bringing this up to be a top level property and, then delete it so there's no dupes.
  delete rest.locale;

  return {
    [RSAA]: {
      endpoint: baseURL + 'enrollments',
      method: 'POST',
      body: JSON.stringify({
        user_data: { ...rest, ...getUrlParams(window.location.search) },
        recaptcha_token,
        form_action,
        locale: locale,
      }),
      headers: {
        ...authHeader(),
        ...userAgentMetaHeaders(meta),
      },
      types: [ParticipantActionType.USER_REQUEST, SUCCESS, FAILURE],
      credentials: 'same-origin',
    },
  };
};

export const getUser = (enrollment_identifier: string, auth_token: string) => ({
  [RSAA]: {
    endpoint: baseURL + `enrollments/${enrollment_identifier}`,
    method: `GET`,
    headers: authHeader(auth_token),
    types: [ParticipantActionType.USER_REQUEST, SUCCESS, FAILURE],
  },
});

export const setUser = (participant: PARTICIPANT_DATA) => ({
  type: ParticipantActionType.USER_LOGIN,
  payload: participant,
});

export const deleteAllMessages = (
  enrollment_identifier: string,
  auth_token: string,
) => ({
  [RSAA]: {
    endpoint: v3URL + `enrollments/${enrollment_identifier}/messages`,
    method: `DELETE`,
    headers: authHeader(auth_token),
    types: [ParticipantActionType.USER_REQUEST, SUCCESS, FAILURE],
  },
});

export const paramString = (
  list: Array<string> = [],
  urlParams: { [key: string]: any },
) =>
  `?${list
    .map((param: string) => urlParams[param] && `${param}=${urlParams[param]}`)
    .filter((e) => e)
    .join('&')}`;

export const getSurvey = (slug: string, urlParams: Array<string>) => {
  return {
    [RSAA]: {
      endpoint:
        v3URL +
        `surveys/${slug}${paramString(
          ['enrollment_identifier', 'occurrence', 'auth_token'],
          urlParams,
        )}`,
      method: 'GET',
      types: [
        'USER/REQUEST',
        {
          type: ParticipantActionType.USER_LOGIN,
          payload: (
            action: AnyAction,
            state: PARTICIPANT_DATA,
            res: Response,
          ) =>
            res.json().then((newres: PARTICIPANT_DATA) => ({
              nodes: [newres],
              layout: newres.layout,
            })),
        },
        log_failure_with_request_data(urlParams),
      ],
    },
  };
};

export const submitSurveyEndpoint = (slug: string, params: any) =>
  v3URL + `surveys/${slug}/answers${paramString(['auth_token'], params)}`;

export const submitSurvey = (
  contributions: any,
  slug: string,
  urlParams: any,
) => ({
  [RSAA]: {
    endpoint: submitSurveyEndpoint(slug, urlParams),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      enrollment_identifier: urlParams.enrollment_identifier,
      occurrence: urlParams.occurrence,
      contributions,
    }),
    types: [
      SurveyActionType.SUBMIT,
      {
        type: SurveyActionType.SUBMIT_SUCCESS,
        payload: (action: AnyAction, state: any, res: Response) =>
          res.json().then((newres: Node) => ({
            nodes: [newres],
          })),
      },
      FAILURE,
    ],
  },
});

export const closeInteractionEndpoint = (auth_token: string) =>
  v3URL + `interactions/close?auth_token=${auth_token}`;

export const closeInteraction = (auth_token: string) => ({
  [RSAA]: {
    endpoint: closeInteractionEndpoint(auth_token),
    method: 'PATCH',
    types: [
      InteractionActionType.CLOSE_REQUEST,
      {
        type: InteractionActionType.CLOSE_SUCCESS,
        payload: (action: AnyAction, state: PARTICIPANT_DATA, res: Response) =>
          res,
      },
      FAILURE,
    ],
  },
});

export const submitSurveyAndCloseInteraction = (
  contributions: any,
  slug: string,
) => {
  const urlParams: { auth_token: string } = getUrlParams(
    window.location.search,
  ) as { auth_token: string };
  return async (dispatch: any, getState: any) => {
    const submitSurveyResponse = await dispatch(
      submitSurvey(contributions, slug, urlParams),
    );

    // triage returns an empty form after receiving the last page submission
    const surveyCompleted = _.isEmpty(
      _.get(submitSurveyResponse, 'payload.nodes[0].content.form'),
    );
    if (!submitSurveyResponse.error && surveyCompleted) {
      const closeInteractionResponse = await dispatch(
        closeInteraction(urlParams.auth_token),
      );
      if (closeInteractionResponse.error)
        submitSurveyResponse.error = 'Failed to close interaction';
    }
    return submitSurveyResponse;
  };
};

export const submitSurveyData = (
  contributions: any,
  enrollment_identifier: string,
  node_id: string,
  auth_token: string,
) => {
  return {
    [RSAA]: {
      endpoint: `${v3URL}enrollments/${enrollment_identifier}/surveys/${node_id}/answers`,
      method: 'POST',
      headers: authHeader(auth_token),
      body: JSON.stringify({ contributions }),
      types: [
        SurveyActionType.SUBMIT,
        {
          type: SurveyActionType.SUBMIT_SUCCESS,
          payload: (
            action: AnyAction,
            state: PARTICIPANT_DATA,
            res: Response,
          ) => res.json().then((newres: PARTICIPANT_DATA) => newres),
        },
        FAILURE,
      ],
    },
  };
};

export const goBackSurveyPage = (
  action_type: string,
  idempotency_token: string,
  enrollment_identifier: string,
  surveyIdentifier,
  auth_token: string,
  occurrence?: any,
) => {
  return {
    [RSAA]: {
      endpoint: `${v3URL}enrollments/${enrollment_identifier}/surveys/${surveyIdentifier}/session`,
      method: 'PATCH',
      headers: authHeader(auth_token),
      body: JSON.stringify({ action_type, idempotency_token, occurrence }),
      types: [
        SurveyActionType.GO_BACK,
        {
          type: SurveyActionType.GO_BACK,
          payload: (
            action: AnyAction,
            state: PARTICIPANT_DATA,
            res: Response,
          ) => res.json().then((newres: PARTICIPANT_DATA) => newres),
        },
        FAILURE,
      ],
    },
  };
};

export const emitEvent = (
  data: any,
  enrollment_identifier: string,
  auth_token: string,
) => {
  return {
    [RSAA]: {
      endpoint: v3URL + `enrollments/${enrollment_identifier}/emit_event`,
      method: `POST`,
      body: JSON.stringify(data),
      headers: authHeader(auth_token),
      types: [
        ParticipantActionType.USER_REQUEST,
        SUCCESS,
        log_failure_with_request_data(data),
      ],
    },
  };
};

export const destroyAuthToken = (
  enrollment_identifier: string,
  participant_auth_token: string,
  device_auth_token?: string,
) => ({
  [RSAA]: {
    endpoint:
      v3URL +
      `enrollments/${enrollment_identifier}/participant_auth_tokens/${
        device_auth_token ?? ''
      }`,
    method: `DELETE`,
    headers: authHeader(participant_auth_token),
    types: [ParticipantActionType.USER_REQUEST, SUCCESS, FAILURE],
  },
});

export const getActiveDevices = (
  enrollment_identifier: string,
  auth_token: string,
) => ({
  [RSAA]: {
    endpoint:
      v3URL + `enrollments/${enrollment_identifier}/participant_auth_tokens`,
    method: 'GET',
    headers: authHeader(auth_token),
    types: [ParticipantActionType.USER_REQUEST, SUCCESS, FAILURE],
  },
});

export const logoutUser = ({
  sessionExpired = false,
  redirect = undefined,
  source = 'user',
}: {
  sessionExpired?: boolean;
  redirect?: string;
  source?: 'user' | 'auto';
} = {}) => {
  api.cookieStorage.remove();
  api.cookie.remove(COOKIE_PARTICIPANT_AUTH);
  api.cookie.remove(COOKIE_PORTAL_EMAIL, { domain: '.myachievement.com' });
  api.cookie.remove(COOKIE_ACM_ID, { domain: '.myachievement.com' });
  const customURL = store.getState().meta.authentication.details?.logout;
  const wasURLReplaced =
    store.getState().meta.authentication.details?.urlReplaced;
  const redirectUrl = wasURLReplaced
    ? customURL!
    : sessionExpired
    ? getSessionExpiredRedirectUrl(source)
    : redirect ?? BASE_HREF;
  window.location.href = redirectUrl;
  return { type: 'USER/LOGOUT' };
};

export const establishUser = (payload: any): AnyAction => ({
  type: ParticipantActionType.USER_LOGIN,
  payload,
});

export const updateAcmInfo = (payload: any): AnyAction => ({
  type: ParticipantActionType.USER_UPDATE_ACM,
  payload,
});

export const setAuthTokenExpiration = (payload: Date | null): AnyAction => ({
  type: ParticipantActionType.USER_SET_AUTH_TOKEN_EXPIRATION,
  payload,
});
