import React from 'react';

export default (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) => {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      role="graphics-symbol"
      //@ts-expect-error TODO: correct above type as its not referencing the right SVGElement
      alt="Close Icon"
      {...props}
    >
      <title>icon_crossmark_1</title>
      <desc>Created with Sketch.</desc>
      <defs />
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <g
          transform="translate(-1292.000000, -1993.000000)"
          stroke="#5B6B7D"
          strokeWidth="3"
        >
          <g transform="translate(0.000000, 1871.000000)">
            <g transform="translate(132.000000, 112.000000)">
              <g transform="translate(1162.000000, 12.000000)">
                <path d="M0,0 L12,12" />
                <path d="M12,0 L0,12" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
