import React, { Component } from 'react';
import { ThemedButton } from './themedButton';
import ContactModal from '../../../components/contactModal';
import CuriousPanda from '../../../components/CuriousPanda';
import Modal from '../../../components/styledModal';
import logger from '../../../utils/errorLogger';
import { WithTranslation, withTranslation } from 'react-i18next';
import _ from 'lodash';
import styled from 'styled-components';
import type { GetAllData } from 'src/app';
import { InjectedFormProps } from 'redux-form';
import api from '../../../services';
import { connect } from 'react-redux';
import { RootState } from 'src/redux';

const FormWrapper = styled.div<{ isFormDisplayed: boolean }>`
  min-height: ${(props) => (props.isFormDisplayed ? ' 300px' : '200px')};
`;

type OptOutModalProps = {
  confirmEmail: string;
  isOpen?: boolean;
  getAllData: GetAllData;
  copy: any;
  selfWithdrawalAllowed?: boolean;
  onClose: () => any;
  header: React.ReactNode;
  participant: any;
} & WithTranslation;

type OptOutModalState = {
  message: string;
  status: string | undefined;
};

type OptOutFormValues = {
  opt_out_reason: string;
};
export class OptOutModal extends Component<OptOutModalProps, OptOutModalState> {
  constructor(props: OptOutModalProps) {
    super(props);
    this.state = { message: ``, status: undefined };
    this.submit = this.submit.bind(this);
    this.setMessage = this.setMessage.bind(this);
  }

  setMessage(message: string, status: string | undefined) {
    this.setState({ message, status });
  }

  submit(data: OptOutFormValues) {
    const { participant_auth_token, enrollment_identifier } =
      this.props.participant;
    const { t } = this.props;
    const successMsg = t('components.optOutModal.successMessage');
    const errorMsg = t('components.optOutModal.errorMessage');

    return api
      .withdraw(enrollment_identifier, participant_auth_token, data)
      .then(() => {
        this.setMessage(successMsg, `success`);
        this.props.getAllData();
      })
      .catch((error) => {
        logger.error(error);
        this.setMessage(errorMsg, `error`);
      });
  }

  generateForm(isFormDisplayed: boolean) {
    const form: any = [
      {
        input: [
          {
            label: this.props.copy.dashboard_withdrawal_modal_paragragh_1,
            interface: 'paragraph',
          },
        ],
      },
    ];
    const { t } = this.props;
    const withdrawalPossibleAnswers = [
      'timeCommitment',
      'studyActivities',
      'activitiesComplicated',
      'anotherStudy',
      'lackOfFeedback',
      'enoughCompensation',
      'notSharingData',
      'interestedParticipating',
      'other',
      'notSay',
    ];
    const possible_answers = withdrawalPossibleAnswers.map((answer) => ({
      label: t(`components.optOutModal.withdrawalPossibleAnswers.${answer}`),
      value: _.snakeCase(answer),
    }));
    if (isFormDisplayed) {
      form[0].input.push(
        {
          label: this.props.copy.dashboard_withdrawal_modal_reason,
          interface: 'paragraph',
        },
        {
          inputStyle: `achievement`,
          name: 'opt_out_reason',
          interface: 'select',
          required: true,
          possible_answers,
        },
      );
    }
    return form;
  }

  submitButton = ({
    valid,
    pristine = false,
    submitting = false,
  }: InjectedFormProps) => {
    // Logic to determine if submit should be deactivated
    // in this instance we are checking to see if we have initial values defined
    // as If we have already populated the form (for CRF ONLY), it is ready to be submitted
    // most other cases do not require this.
    const isDisabled = !valid || submitting || pristine;
    const isFormDisplayed = this.props.selfWithdrawalAllowed !== false;
    return isFormDisplayed ? (
      <ThemedButton
        isLoading={submitting}
        variant="primary"
        id="t-submit_button"
        size="large"
        style={{ width: '100%', justifyContent: 'center' }}
        type="submit"
        disabled={isDisabled}
      >
        {this.props.copy.dashboard_withdrawal_link}
      </ThemedButton>
    ) : null;
  };

  renderParagraph2 = () => (
    <p className="mb20 mb30">
      {this.props.copy.dashboard_withdrawal_modal_paragragh_2}
    </p>
  );

  render() {
    const { header, onClose, isOpen = true } = this.props;
    const { message, status } = this.state;
    const isFormDisplayed = this.props.selfWithdrawalAllowed !== false;
    return (
      <React.Fragment>
        <Modal header={header} isOpen={isOpen} closeModal={onClose}>
          <FormWrapper isFormDisplayed={isFormDisplayed}>
            <CuriousPanda
              form="opt_out"
              schema={this.generateForm(isFormDisplayed)}
              onSubmit={this.submit}
            >
              {this.submitButton}
            </CuriousPanda>
          </FormWrapper>

          {message !== `` && (
            <div
              className={`change-password-success ${status} text-small mt20`}
            >
              {message}
            </div>
          )}

          {this.renderParagraph2()}

          <ContactModal>
            {({ showModal }: { showModal: () => any }) => (
              <ThemedButton
                onClick={showModal}
                variant="secondary"
                style={{ width: '100%', justifyContent: 'center' }}
              >
                Contact Us
              </ThemedButton>
            )}
          </ContactModal>
        </Modal>
      </React.Fragment>
    );
  }
}

export default connect(({ participant }: RootState) => ({ participant }))(
  withTranslation()(OptOutModal),
);
