// @ts-nocheck
// TODO: npm i --save-dev @types/react-loadable
import React from 'react';
import Loadable from 'react-loadable';
import { CommonLoadable } from './utils';

export const Unauthorized = Loadable({
  ...CommonLoadable,
  loader: () => import('../routes/unauthorized'),
});

export const Education = Loadable({
  ...CommonLoadable,
  loader: () => import('../routes/education'),
});

export const LogIn = Loadable({
  ...CommonLoadable,
  loader: () => import('../routes/log_in'),
});

export const SignUp = Loadable({
  ...CommonLoadable,
  loader: () => import('../routes/sign-up'),
});

export const ThankYou = Loadable.Map({
  ...CommonLoadable,
  loader: {
    Enrollment: () => import('../routes/enrollment'),
    ThankYou: () => import('../routes/dashboard/thankyou'),
  },
  render(loaded, props) {
    const Wrapper = loaded.Enrollment.default;
    const ThankYou = loaded.ThankYou.default;
    return (
      <Wrapper {...props}>
        <ThankYou />
      </Wrapper>
    );
  },
});

export const Enrollment = Loadable.Map({
  ...CommonLoadable,
  loader: {
    Enrollment: () => import('../routes/enrollment'),
    Screener: () => import('../routes/enrollment/screener'),
  },
  render(loaded, props) {
    const Wrapper = loaded.Enrollment.default;
    const Screener = loaded.Screener.default;
    return (
      <Wrapper {...props}>
        <Screener {...props} />
      </Wrapper>
    );
  },
});

export const GenericNonDashboard = Loadable.Map({
  ...CommonLoadable,
  loader: {
    Enrollment: () => import('../routes/enrollment'),
    GenericNonDashboard: () =>
      import('../routes/enrollment/generic_non_dashboard'),
  },
  render(loaded, props) {
    const Wrapper = loaded.Enrollment.default;
    const GenericNonDashboard = loaded.GenericNonDashboard.default;
    return (
      <Wrapper {...props}>
        <GenericNonDashboard {...props} />
      </Wrapper>
    );
  },
});

export const Dashboard = Loadable({
  ...CommonLoadable,
  loader: () => import('../routes/dashboard'),
});

export const Settings = Loadable({
  ...CommonLoadable,
  loader: () => import('../routes/settings'),
});

export const Contribution = Loadable({
  ...CommonLoadable,
  loader: () => import('../routes/contribution'),
});

export const EHR = Loadable({
  ...CommonLoadable,
  loader: () => import('../routes/ehr'),
});

export const FollowUp = Loadable({
  ...CommonLoadable,
  loader: () => import('../routes/follow_up'),
});

export const Summary = Loadable({
  ...CommonLoadable,
  loader: () => import('../routes/summary'),
});

export const TerminalState = Loadable({
  ...CommonLoadable,
  loader: () => import('../routes/terminal_state'),
});

export const OneClickError = Loadable({
  ...CommonLoadable,
  loader: () => import('../routes/one_click_contribution/error'),
});

export const OneClick = Loadable({
  ...CommonLoadable,
  loader: () => import('../routes/one_click_contribution'),
});

export const EmailVerification = Loadable({
  ...CommonLoadable,
  loader: () => import('../routes/email_verification'),
});

export const RedirectUser = Loadable({
  ...CommonLoadable,
  loader: () => import('../routes/redirect_user'),
});

export const CamCogSuccess = Loadable({
  ...CommonLoadable,
  loader: () => import('../routes/camcog_success'),
});

export const VerifyIdentity = Loadable({
  ...CommonLoadable,
  loader: () => import('../routes/verify_identity'),
});

export const DisplayUserError = Loadable({
  ...CommonLoadable,
  loader: () => import('../routes/DisplayUserError'),
});

export const OneClickPage = Loadable({
  ...CommonLoadable,
  loader: () => import('../routes/dashboard/OneClick'),
});

export const ForgotPassword = Loadable({
  ...CommonLoadable,
  loader: () => import('../routes/ForgotPassword'),
});
