var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled, { css } from 'styled-components';
import { LoadingIndicator } from '../LoadingIndicator';
import { color, palette, getSpacingScale } from '../system';
var primaryVariant = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: ", ";\n  color: ", ";\n  &:hover {\n    background: ", ";\n  }\n  &:focus {\n    background: ", ";\n    outline: 2px solid ", ";\n    outline-offset: 3px;\n  }\n  &:active {\n    background: ", ";\n  }\n  &:disabled {\n    background: ", ";\n    color: ", ";\n  }\n"], ["\n  background: ", ";\n  color: ", ";\n  &:hover {\n    background: ", ";\n  }\n  &:focus {\n    background: ", ";\n    outline: 2px solid ", ";\n    outline-offset: 3px;\n  }\n  &:active {\n    background: ", ";\n  }\n  &:disabled {\n    background: ", ";\n    color: ", ";\n  }\n"])), color.status.informational, color.text.white, color.states.hover, color.states.focus, color.borders.focus, color.states.pressed, color.states.disabled, color.text.disabled);
export var variants = {
    primary: primaryVariant,
    transparent: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    background: transparent;\n    border: none !important;\n  "], ["\n    background: transparent;\n    border: none !important;\n  "]))),
    danger: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    background: ", ";\n    color: ", ";\n  "], ["\n    background: ", ";\n    color: ", ";\n  "])), color.status.error, color.text.white),
    secondary: css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    background: transparent;\n    color: ", ";\n    border: 1px solid ", ";\n    &:hover {\n      background: ", ";\n    }\n    &:active {\n      background: ", ";\n    }\n    &:disabled {\n      border-color: ", ";\n      color: ", ";\n    }\n  "], ["\n    background: transparent;\n    color: ", ";\n    border: 1px solid ", ";\n    &:hover {\n      background: ", ";\n    }\n    &:active {\n      background: ", ";\n    }\n    &:disabled {\n      border-color: ", ";\n      color: ", ";\n    }\n  "])), color.status.informational, color.borders.focus, color.status.informational_subdued, palette.blue[10], color.borders.disabled, color.text.disabled),
    light: css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    background: ", ";\n    color: ", ";\n    &:hover {\n      background: ", ";\n    }\n    &:focus {\n      outline: 1px solid ", ";\n    }\n    &:active,\n    .active {\n      background: ", ";\n    }\n    &:disabled {\n      border-color: ", ";\n      color: ", ";\n    }\n  "], ["\n    background: ", ";\n    color: ", ";\n    &:hover {\n      background: ", ";\n    }\n    &:focus {\n      outline: 1px solid ", ";\n    }\n    &:active,\n    .active {\n      background: ", ";\n    }\n    &:disabled {\n      border-color: ", ";\n      color: ", ";\n    }\n  "])), color.status.informational_subdued, color.status.informational, palette.blue[10], color.borders.focus, palette.blue[20], color.borders.disabled, color.text.disabled),
    'floating-action': css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    ", "\n    border-radius: 100%;\n    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2), 0px 0px 1px #d9d8d9;\n    padding: 12px;\n    & svg {\n      margin: 0;\n    }\n  "], ["\n    ", "\n    border-radius: 100%;\n    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2), 0px 0px 1px #d9d8d9;\n    padding: 12px;\n    & svg {\n      margin: 0;\n    }\n  "])), primaryVariant),
    ghost: css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    background: transparent;\n    color: ", ";\n    &:hover {\n      background: ", ";\n    }\n    &:focus {\n      outline: 1px solid ", ";\n    }\n    &:active {\n      background: ", ";\n    }\n    &:disabled {\n      border-color: ", ";\n      color: ", ";\n    }\n\n    &.active {\n      background: ", ";\n    }\n  "], ["\n    background: transparent;\n    color: ", ";\n    &:hover {\n      background: ", ";\n    }\n    &:focus {\n      outline: 1px solid ", ";\n    }\n    &:active {\n      background: ", ";\n    }\n    &:disabled {\n      border-color: ", ";\n      color: ", ";\n    }\n\n    &.active {\n      background: ", ";\n    }\n  "])), color.status.informational, palette.blue[10], color.borders.focus, palette.blue[20], color.borders.disabled, color.text.disabled, palette.blue[20]),
    link: css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    background: transparent;\n    text-decoration: underline;\n  "], ["\n    background: transparent;\n    text-decoration: underline;\n  "]))),
};
var getVariant = function (props) {
    var _a, _b;
    if (props.variant) {
        if (props === null || props === void 0 ? void 0 : props.variants) {
            var allVariants = __assign(__assign({}, variants), props.variants);
            return (_a = allVariants === null || allVariants === void 0 ? void 0 : allVariants[props.variant]) !== null && _a !== void 0 ? _a : '';
        }
        return (_b = variants === null || variants === void 0 ? void 0 : variants[props.variant]) !== null && _b !== void 0 ? _b : '';
    }
    return css(templateObject_9 || (templateObject_9 = __makeTemplateObject([""], [""])));
};
var StyledButton = styled.button(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  box-sizing: border-box;\n  border: 0;\n  font-weight: 600;\n  border-radius: 4px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n  & svg {\n    margin-left: 4px;\n    margin-right: 4px;\n  }\n  /** Run our variant specific processing */\n  padding: ", ";\n  ", ";\n  transition: background-color 0.25s ease;\n"], ["\n  box-sizing: border-box;\n  border: 0;\n  font-weight: 600;\n  border-radius: 4px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n  & svg {\n    margin-left: 4px;\n    margin-right: 4px;\n  }\n  /** Run our variant specific processing */\n  padding: ", ";\n  ", ";\n  transition: background-color 0.25s ease;\n"])), function (props) { return getSpacingScale('buttons', props.size); }, function (props) { return getVariant(props); });
var Button = function (_a) {
    var _b = _a.variant, variant = _b === void 0 ? 'primary' : _b, _c = _a.size, size = _c === void 0 ? 'medium' : _c, children = _a.children, isLoading = _a.isLoading, rest = __rest(_a, ["variant", "size", "children", "isLoading"]);
    // need to pass this as any due to styled components
    // we need to upgrade this library badly.
    return (React.createElement(StyledButton, __assign({ variant: variant, size: size, "data-testid": "t-button" }, rest),
        children,
        isLoading && React.createElement(LoadingIndicator, { height: 12, width: 12 })));
};
export default Button;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
