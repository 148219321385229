"use strict";

require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.symbol.js");
require("core-js/modules/es.array.filter.js");
require("core-js/modules/es.object.get-own-property-descriptor.js");
require("core-js/modules/es.array.for-each.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.object.get-own-property-descriptors.js");
require("core-js/modules/es.object.define-properties.js");
require("core-js/modules/es.object.define-property.js");
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.globalTranslate = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _LanguageContext = require("../contexts/LanguageContext");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/**
 * @description Utility function to translate keys in non-react component function. (i.e.:
 * validation functions for form fields) This will set up the namespace needed for the
 * translations. Warning this requires that a global instance of i18next be initialized
 * otherwise only the key will be returned
 * @param {String} key - identifies the string to return for translation
 * @param {Object} options - i18next options for translations
 * @returns {String}
 */
var globalTranslate = exports.globalTranslate = function globalTranslate(key) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return _LanguageContext.localI18n !== null && _LanguageContext.localI18n !== void 0 && _LanguageContext.localI18n.isInitialized ? _LanguageContext.localI18n.t(key, _objectSpread({
    ns: _LanguageContext.curiousPandaNamespace
  }, options)) : key;
};