"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.array.filter.js");
require("core-js/modules/es.object.get-own-property-descriptor.js");
require("core-js/modules/es.array.for-each.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.object.get-own-property-descriptors.js");
require("core-js/modules/es.object.define-properties.js");
require("core-js/modules/es.object.define-property.js");
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.util_commonInputParamters = exports.prepareEmptyFields = exports.constructFieldClassname = exports.checkboxOptionNormalization = exports.alwaysGetKeyValue = exports.ArrayMoveUp = exports.ArrayMoveDown = void 0;
require("core-js/modules/es.array.join.js");
require("core-js/modules/es.array.reduce.js");
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.split.js");
require("core-js/modules/es.string.trim.js");
require("core-js/modules/es.string.replace.js");
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _lodash = _interopRequireDefault(require("lodash"));
var _utils = require("../utils");
var _excluded = ["dispatch", "form", "warning", "initial"],
  _excluded2 = ["error", "touched"],
  _excluded3 = ["id", "className", "items", "answer", "displayLabel", "component", "split", "question_description"],
  _excluded4 = ["meta", "input", "config", "ComponentWrapper", "label", "accepts_data"],
  _excluded5 = ["answer"],
  _excluded6 = ["value"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var never_pass_to_inputs = ["fieldsFromRedux", "valuesFromRedux", "accepts_data", "noneLabel", "optional_for_completion", "displayRow", "displayMessage", "remoteSubmit", "nowDate"];

// constructs a string based on field state key/boolean objects
var constructFieldClassname = exports.constructFieldClassname = function constructFieldClassname(_ref, className) {
  var dispatch = _ref.dispatch,
    form = _ref.form,
    warning = _ref.warning,
    initial = _ref.initial,
    args = (0, _objectWithoutProperties2["default"])(_ref, _excluded);
  return _lodash["default"].reduce(args, function (acc, state, key) {
    return state ? [].concat((0, _toConsumableArray2["default"])(acc), [key]) : acc;
  }, ["".concat(className)]).join(" ");
};
var prepareEmptyFields = exports.prepareEmptyFields = function prepareEmptyFields(fields) {
  var keydObject = _lodash["default"].isArray(fields) ? _lodash["default"].reduce(fields, function (acc, item) {
    return _objectSpread(_objectSpread({}, acc), {}, (0, _defineProperty2["default"])({}, item.name, item));
  }, {}) : fields;
  return Object.keys(keydObject).reduce(function (acc, key) {
    var component = keydObject[key].component;
    var value = undefined; // default is undefined

    switch (component.toLowerCase()) {
      case "checkboxinput":
        value = keydObject[key]['checked'] || false;
        break;
      case "array":
      case "flatarray":
        value = [];
        break;
      case "numberinput":
        value = 0;
        break;
      case "textareainput":
      case "textinput":
      case "text":
      default:
        value = "";
        break;
    }
    return _objectSpread(_objectSpread({}, acc), {}, (0, _defineProperty2["default"])({}, key, value));
  }, {});
};
var util_commonInputParamters = exports.util_commonInputParamters = function util_commonInputParamters(_ref2) {
  var _ref2$meta = _ref2.meta,
    _ref2$meta$error = _ref2$meta.error,
    error = _ref2$meta$error === void 0 ? "" : _ref2$meta$error,
    _ref2$meta$touched = _ref2$meta.touched,
    touched = _ref2$meta$touched === void 0 ? false : _ref2$meta$touched,
    meta = (0, _objectWithoutProperties2["default"])(_ref2$meta, _excluded2),
    _ref2$input = _ref2.input,
    input = _ref2$input === void 0 ? {} : _ref2$input,
    _ref2$config = _ref2.config,
    _ref2$config$id = _ref2$config.id,
    elemtntId = _ref2$config$id === void 0 ? "" : _ref2$config$id,
    _ref2$config$classNam = _ref2$config.className,
    className = _ref2$config$classNam === void 0 ? "" : _ref2$config$classNam,
    items = _ref2$config.items,
    answer = _ref2$config.answer,
    _ref2$config$displayL = _ref2$config.displayLabel,
    displayLabel = _ref2$config$displayL === void 0 ? true : _ref2$config$displayL,
    component = _ref2$config.component,
    split = _ref2$config.split,
    question_description = _ref2$config.question_description,
    config = (0, _objectWithoutProperties2["default"])(_ref2$config, _excluded3),
    ComponentWrapper = _ref2.ComponentWrapper,
    label = _ref2.label,
    accepts_data = _ref2.accepts_data,
    rest = (0, _objectWithoutProperties2["default"])(_ref2, _excluded4);
  // prioritizing 'name' field over label for testing path
  // this path matches the field data submission key 'name' prepended with t-
  var id = _lodash["default"].trim(elemtntId !== "" ? elemtntId : "t-".concat(config.name ? config.name.replace(/\s/g, "") : (0, _utils.stripSpecialChars)(label)));
  var output = _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, input), rest), _lodash["default"].omit(config, ["value"])), {}, {
    label: displayLabel ? label : "",
    error: touched && error ? error : "",
    className: constructFieldClassname(_objectSpread(_objectSpread({}, meta), {}, {
      error: error,
      touched: touched
    }), className),
    id: id
  });
  return _lodash["default"].omit(output, never_pass_to_inputs);
};
var alwaysGetKeyValue = exports.alwaysGetKeyValue = function alwaysGetKeyValue(item) {
  // normalzing data so that we always have an apropriate key/value pair
  // our apis don't always provide consistant data, so this is just normalizing data
  var output = {
    id: (item === null || item === void 0 ? void 0 : item.id) || null
  };
  if (_lodash["default"].isString(item)) {
    // covering the case where this is just an array of items
    output = {
      label: item,
      value: _lodash["default"].snakeCase(item)
    };
  } else if (_lodash["default"].has(item, "answer")) {
    // covering the case where we have an answer key
    var answer = item.answer,
      rest = (0, _objectWithoutProperties2["default"])(item, _excluded5);
    var lastKey = Object.keys(rest)[0];
    if (_lodash["default"].has(rest, "label")) {
      output = {
        label: item['label'],
        value: answer,
        id: (item === null || item === void 0 ? void 0 : item.identifier) || (item === null || item === void 0 ? void 0 : item.id)
      };
    } else {
      console.error('answer not containing label value', item);
      output = {
        label: item[lastKey],
        value: answer,
        id: (item === null || item === void 0 ? void 0 : item.identifier) || (item === null || item === void 0 ? void 0 : item.id)
      };
    }
  } else if (_lodash["default"].has(item, "value")) {
    // covering the case where we have a value key
    var value = item.value,
      _rest = (0, _objectWithoutProperties2["default"])(item, _excluded6);
    var _lastKey = Object.keys(_rest)[0];
    output = {
      label: item[_lastKey],
      value: value
    };
  } else if (_lodash["default"].has(item, "label")) {
    var label = item.label;
    output = {
      label: label,
      value: undefined,
      id: item === null || item === void 0 ? void 0 : item.id
    };
  } else {
    var key = Object.keys(item)[0];
    output = {
      label: key,
      value: item[key]
    };
  }
  return output;
};
var checkboxOptionNormalization = exports.checkboxOptionNormalization = function checkboxOptionNormalization(options) {
  // covering the case where options are not provided, or only a string is provided.
  if (_lodash["default"].isString(options)) {
    return [{
      label: options,
      answer: options
    }];
  } else if (_lodash["default"].isEmpty(options)) {
    // if no label is found, this is for an 'acceptance' checkbox, we just need a value if it's checked
    return [{
      label: "",
      answer: "true"
    }];
  }
  return options;
};
var ArrayMoveUp = exports.ArrayMoveUp = function ArrayMoveUp(e, index, fields) {
  e.preventDefault();
  index - 1 >= 0 && fields.move(index, index - 1);
};
var ArrayMoveDown = exports.ArrayMoveDown = function ArrayMoveDown(e, index, fields) {
  e.preventDefault();
  index + 1 < fields.length && fields.move(index, index + 1);
};