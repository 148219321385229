import React from 'react';

export default (props = {}) => (
  <svg
    data-test-id="language_globe"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="graphics-symbol"
    {...props}
  >
    <title>Globe Icon</title>
    <g opacity="0.6">
      <path
        d="M8 0C8.23134 0 8.48465 0.116888 8.77344 0.429688C9.06222 0.742487 9.35616 1.24051 9.60547 1.87891C9.83852 2.47576 10.0285 3.19976 10.1699 4H5.83008C5.9715 3.19976 6.16148 2.47576 6.39453 1.87891C6.64384 1.24051 6.93778 0.742487 7.22656 0.429688C7.51535 0.116888 7.76866 0 8 0ZM6.09961 0.265625C5.86047 0.625741 5.64785 1.04191 5.46289 1.51562C5.18224 2.23438 4.95913 3.07409 4.80664 4H1.67969C2.53707 2.19289 4.14111 0.815769 6.09961 0.265625ZM9.90039 0.265625C11.8589 0.815769 13.4629 2.19289 14.3203 4H11.1934C11.0409 3.07409 10.8178 2.23438 10.5371 1.51562C10.3521 1.04191 10.1395 0.625741 9.90039 0.265625ZM1.29297 5H4.6875C4.62027 5.64249 4.57227 6.30443 4.57227 7C4.57227 7.6956 4.62027 8.35721 4.6875 9H1.29297C1.1045 8.36567 1 7.69631 1 7C1 6.30435 1.10485 5.6338 1.29297 5ZM5.69531 5H10.3047C10.3762 5.63819 10.4277 6.30078 10.4277 7C10.4277 7.69993 10.3763 8.36092 10.3047 9H5.69531C5.62367 8.36092 5.57227 7.69993 5.57227 7C5.57227 6.30078 5.62381 5.63819 5.69531 5ZM11.3125 5H14.707C14.8952 5.6338 15 6.30435 15 7C15 7.69631 14.8955 8.36567 14.707 9H11.3125C11.3797 8.35721 11.4277 7.6956 11.4277 7C11.4277 6.30443 11.3797 5.64249 11.3125 5ZM1.68164 10H4.80664C4.95913 10.9264 5.18224 11.7676 5.46289 12.4863C5.64747 12.959 5.85913 13.3728 6.09766 13.7324C4.14083 13.1822 2.53904 11.8054 1.68164 10ZM5.83203 10H10.168C10.0266 10.7999 9.83831 11.5267 9.60547 12.123C9.35616 12.7615 9.06222 13.2595 8.77344 13.5723C8.48465 13.8851 8.23134 14 8 14C7.76866 14 7.51535 13.8851 7.22656 13.5723C6.93778 13.2595 6.64384 12.7615 6.39453 12.123C6.16169 11.5267 5.97342 10.7999 5.83203 10ZM11.1934 10H14.3184C13.461 11.8054 11.8592 13.1822 9.90234 13.7324C10.1409 13.3728 10.3525 12.959 10.5371 12.4863C10.8178 11.7676 11.0409 10.9264 11.1934 10Z"
        fill="#2A3F53"
      />
    </g>
  </svg>
);
