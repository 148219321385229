"use strict";

require("core-js/modules/es.object.define-property.js");
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _propTypes = _interopRequireDefault(require("prop-types"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var InputError = _styledComponents["default"].div.withConfig({
  displayName: "InputError"
})(["font-size:1rem;padding-top:2px;font-weight:600;margin-bottom:16px;color:#b21f36;display:block;"]);
InputError.propTypes = {
  style: _propTypes["default"].object
};
InputError.defaultProps = {
  style: {}
};
var _default = exports["default"] = InputError;