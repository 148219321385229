"use strict";

require("core-js/modules/es.object.define-property.js");
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validations = exports.validateMinMax = exports.validateDecimal = void 0;
require("core-js/modules/es.number.to-fixed.js");
require("core-js/modules/es.regexp.constructor.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.regexp.to-string.js");
require("core-js/modules/es.string.repeat.js");
require("core-js/modules/es.number.constructor.js");
var _lodash = _interopRequireDefault(require("lodash"));
var _i18n = require("../../i18n");
var validateMinMax = exports.validateMinMax = function validateMinMax(min, max, decimal) {
  return function (value, allValues, props, name) {
    if (allValues !== null && allValues !== void 0 && allValues["".concat(name, "_exclusive")]) {
      return undefined;
    }
    if (value && validInputs(decimal)) {
      var val = _lodash["default"].toNumber(value);
      if (min !== undefined && max !== undefined) {
        if (min <= val && max >= val) {
          return undefined;
        }
      } else {
        if (min !== undefined && val >= min) {
          return undefined;
        } else if (max !== undefined && val <= max) {
          return undefined;
        } else if (min === undefined && max === undefined) {
          return undefined;
        }
      }
      if (min !== undefined && max !== undefined) {
        return (0, _i18n.globalTranslate)('validations.number.minMax', {
          min: min.toFixed(decimal),
          max: max.toFixed(decimal)
        });
      }
      if (min !== undefined && max === undefined) {
        return (0, _i18n.globalTranslate)('validations.number.min', {
          min: min.toFixed(decimal)
        });
      }
      if (min === undefined && max !== undefined) {
        return (0, _i18n.globalTranslate)('validations.number.max', {
          max: max.toFixed(decimal)
        });
      }
    }
    return undefined;
  };
};
var validateDecimal = exports.validateDecimal = function validateDecimal(decimal) {
  return function (value, allValues, props, name) {
    if (allValues !== null && allValues !== void 0 && allValues["".concat(name, "_exclusive")]) {
      return undefined;
    }
    if (value && validInputs(decimal)) {
      var pattern = decimal > 0 ? "^[-+]?\\d+(?:\\.\\d{1,".concat(decimal, "})?$") : "^-?\\d+$";
      var regex = new RegExp(pattern);
      if (regex.test(value)) {
        return undefined;
      } else {
        return (0, _i18n.globalTranslate)('validations.number.decimal', {
          decimal: decimal,
          zeros: decimal > 0 ? '.' + 'X'.repeat(decimal) : ''
        });
      }
    }
  };
};
var validInputs = function validInputs(v) {
  return !_lodash["default"].isNaN(v) && _lodash["default"].isNumber(v);
};
var validations = exports.validations = function validations(_ref) {
  var minimum = _ref.minimum,
    maximum = _ref.maximum,
    decimal = _ref.decimal;
  return [validateMinMax(minimum !== undefined ? Number(minimum) : undefined, maximum !== undefined ? Number(maximum) : undefined, decimal), validateDecimal(decimal)];
};